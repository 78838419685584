<template>
	<div>
		<Navbar/>
		<!--
		<script type="application/ld+json">
		[
			{
				"@context": "https://schema.org/",
				"@type": "Product",
				"name": "VPS-1-2020",
				"image": "",
				"description": "1 vCPU @ 3,50 GHz | 2 Go DDR4 | 75 Go RAID10 | 250 Mbps",
				"brand": "HydroSaaS",
				"offers": {
					"@type": "Offer",
					"url": "https://manager.hydrosaas.com/cart.php?a=add&pid=12",
					"priceCurrency": "EUR",
					"price": "4",
					"availability": "https://schema.org/InStock",
					"itemCondition": "https://schema.org/NewCondition"
				}
			},
			{
				"@context": "https://schema.org/",
				"@type": "Product",
				"name": "VPS-2-2020",
				"image": "",
				"description": "2 vCPU @ 3,50 GHz | 4 Go DDR4 | 150 Go RAID10 | 250 Mbps",
				"brand": "HydroSaaS",
				"offers": {
					"@type": "Offer",
					"url": "https://manager.hydrosaas.com/cart.php?a=add&pid=13",
					"priceCurrency": "EUR",
					"price": "6",
					"availability": "https://schema.org/InStock",
					"itemCondition": "https://schema.org/NewCondition"
				}
			},
			{
				"@context": "https://schema.org/",
				"@type": "Product",
				"name": "VPS-3-2020",
				"image": "",
				"description": "3 vCPU @ 3,50 GHz | 6 Go DDR4 | 200 Go RAID10 | 250 Mbps",
				"brand": "HydroSaaS",
				"offers": {
					"@type": "Offer",
					"url": "https://manager.hydrosaas.com/cart.php?a=add&pid=14",
					"priceCurrency": "EUR",
					"price": "8",
					"availability": "https://schema.org/InStock",
					"itemCondition": "https://schema.org/NewCondition"
				}
			},
			{
				"@context": "https://schema.org/",
				"@type": "Product",
				"name": "VPS-4-2020",
				"image": "",
				"description": "4 vCPU @ 3,50 GHz | 8 Go DDR4 | 300 Go RAID10 | 250 Mbps",
				"brand": "HydroSaaS",
				"offers": {
					"@type": "Offer",
					"url": "https://manager.hydrosaas.com/cart.php?a=add&pid=15",
					"priceCurrency": "EUR",
					"price": "10",
					"availability": "https://schema.org/InStock",
					"itemCondition": "https://schema.org/NewCondition"
				}
			}
		]
		</script>
		-->
		<div :style="{'background-image': `url(${require('../assets/img/cover4.jpg')})`, 'background-size': `cover`, 'background-position': 'center'}" class="pt-12">
			<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8" >
				<div class="lg:text-center mb-8">
					<p class="text-base leading-6 text-hydro font-semibold tracking-wide uppercase"> VPS </p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('vps.headline') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-700 lg:mx-auto">
						{{ $t('vps.headline2') }}
					</p>
				</div>
			</div>
		</div>
		<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
			<div class="flex flex-col">
				<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
					<div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
						<table class="min-w-full">
							<thead>
							<tr>
								<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									{{ $t('vps.name') }}
								</th>
								<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									{{ $t('vps.cpu') }}
								</th>
								<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									{{ $t('vps.ram') }}
								</th>
								<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									{{ $t('vps.storage') }}
								</th>
								<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									{{ $t('vps.bandwith') }}
								</th>
								<th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
									{{ $t('vps.price') }}
								</th>
								<th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
							</tr>
							</thead>
							<tbody class="bg-white">
							<tr>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="flex items-center">
										<div class="ml-4">
											<div class="text-sm leading-5 font-medium text-gray-900">VPS-1-2020</div>
										</div>
									</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">1 vCPU @ 3,50 GHz</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">2Go DDR4</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">75Go RAID 10</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">250 mbps</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									4 €
								</td>
								<td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
									<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=12" class="text-white bg-hydro px-4 py-2 hover:bg-hydroactive rounded-full shadow">{{ $t('home.purchase') }}</a>
								</td>
							</tr>
							<tr>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="flex items-center">
										<div class="ml-4">
											<div class="text-sm leading-5 font-medium text-gray-900">VPS-2-2020</div>
										</div>
									</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">2 vCPU @ 3,50 GHz</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">4Go DDR4</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">150Go RAID 10</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">250 mbps</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									6 €
								</td>
								<td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
									<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=13" class="text-white bg-hydro px-4 py-2 hover:bg-hydroactive rounded-full shadow">{{ $t('home.purchase') }}</a>
								</td>
							</tr>
							<tr>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="flex items-center">
										<div class="ml-4">
											<div class="text-sm leading-5 font-medium text-gray-900">VPS-3-2020</div>
										</div>
									</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">3 vCPU @ 3,50 GHz</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">6Go DDR4</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">200Go RAID 10</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">250 mbps</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									8 €
								</td>
								<td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
									<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=14" class="text-white bg-hydro px-4 py-2 hover:bg-hydroactive rounded-full shadow">{{ $t('home.purchase') }}</a>
								</td>
							</tr>
							<tr>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="flex items-center">
										<div class="ml-4">
											<div class="text-sm leading-5 font-medium text-gray-900">VPS-4-2020</div>
										</div>
									</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">4 vCPU @ 3,50 GHz</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">8Go DDR4</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">300Go RAID 10</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">250 mbps</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									10€
								</td>
								<td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
									<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=15" class="text-white bg-hydro px-4 py-2 hover:bg-hydroactive rounded-full shadow">{{ $t('home.purchase') }}</a>
								</td>
							</tr>
							<tr>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="flex items-center">
										<div class="ml-4">
											<div class="text-sm leading-5 font-medium text-gray-900">VPS-5-2020</div>
										</div>
									</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">5vCPU @ 3,50 GHz</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">16Go DDR4</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">400Go RAID 10</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">250 mbps</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									14€
								</td>
								<td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
									<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=22" class="text-white bg-hydro px-4 py-2 hover:bg-hydroactive rounded-full shadow">{{ $t('home.purchase') }}</a>
								</td>
							</tr>
							<tr>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="flex items-center">
										<div class="ml-4">
											<div class="text-sm leading-5 font-medium text-gray-900">VPS-6-2020</div>
										</div>
									</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">6vCPU @ 3,50 GHz</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">32Go DDR4</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">500Go RAID 10</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									<div class="text-sm leading-5 text-gray-900">250 mbps</div>
								</td>
								<td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
									25€
								</td>
								<td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
									<a href="https://manager.hydrosaas.com/cart.php?a=add&pid=30" class="text-white bg-hydro px-4 py-2 hover:bg-hydroactive rounded-full shadow">{{ $t('home.purchase') }}</a>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="lg:text-center">
			<h3 class="mt-2 text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-10">
				{{ $t('vps.os') }}
			</h3>
			<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
				{{ $t('vps.presetos') }}
			</p>
		</div>
		<div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8  text-center">
			<div class="text-center inline-block py-5" style="margin:5px 20px;">
				<img src="../assets/img/debian.png" alt="" class="h-15">
				<p class="text-red-900">Debian</p>
			</div>
			<div class="text-center inline-block py-5" style="margin:5px 20px;">
				<img src="../assets/img/centos.png" alt="" class="h-15">
				<p style="color: #932279">Centos</p>
			</div>
			<div class="text-center inline-block py-5" style="margin:5px 20px;">
				<img src="../assets/img/rocky.png" alt="" class="h-15">
				<p style="color:  #72d079">Rocky</p>
			</div>
			<div class="text-center inline-block py-5" style="margin:5px 20px;">
				<img src="../assets/img/ubuntu_logo.png" alt="" class="h-15">
				<p style="color: #f47421">Ubuntu</p>
			</div>
		</div>
		<div class="lg:text-center">
			<h3 class="mt-2 text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-10">
				{{ $t('vps.otheros') }}
			</h3>
			<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
				{{ $t('vps.oslist') }}
			</p>
		</div>
		<div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8  text-center">
			<div class="text-center inline-block py-5" style="margin:5px 20px;">
				<img src="../assets/img/fedora.png" alt="" class="h-15">
				<p style="color: #294172">Fedora</p>
			</div>
			<div class="text-center inline-block py-5" style="margin:5px 20px;">
				<img src="../assets/img/freebsd.png" alt="" class="h-15">
				<p style="color: #ff3300">FreeBSD</p>
			</div>
			<div class="text-center inline-block py-5" style="margin:5px 20px;">
				<img src="../assets/img/archlinux.png" alt="" class="h-15">
				<p style="color: #2494cf">ArchLinux</p>
			</div>
			<div class="text-center inline-block py-5" style="margin:5px 20px;">
				<img src="../assets/img/freenas.png" alt="" class="h-15">
				<p style="color: #000000">FreeNAS</p>
			</div>
			<div class="text-center inline-block py-5" style="margin:5px 20px;">
				<img src="../assets/img/openmediavault.png" alt="" class="h-15">
				<p style="color: #99b7ce">OMV</p>
			</div>
			<div class="text-center inline-block py-5" style="margin:5px 20px;">
				<img src="../assets/img/pfsense.png" alt="" class="h-15">
				<p style="color: #1e77cc">PfSense</p>
			</div>
			<div class="text-center inline-block py-5" style="margin:5px 20px;">
				<img src="../assets/img/opnsense.png" alt="" class="h-15">
				<p style="color: #d17035">OpenSense</p>
			</div>
		</div>
		<div class="grid sm:grid-cols-2 bg-gray-100">
			<div class="mx-12 my-12">
				<ul>
					<li>
						<h1 class="text-xl font-semibold">
							<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAB8klEQVRoge2Z7ZGCMBRFTwmUkBIsISXQgXRgCdDB2oHbwdqBlLAdSAfawe6PwA4iyXtgQJjlzuQfedyLOfkSNm3aNJX2wBW4AfmbvQxSijP+02lXXKjFygIXno1326V+djEywAnZeLd91X3fpgQ3tkMmP4Gj8MxHXWtWHXBw+kyVPH5dA5wDz88Gug/QthEb6G9x4Xz9JwPdogO0MZEK9TKgCtSIBrrBwTYU0MbETqhfAPdAjdGgJzi4JEAThYkTYUgTIoOeEQb0zPNXSepAr0BqkEHPNAF85kvkcblDhlTiwwZq3DQBYoCVIkPq48MSnjBGB2iPaaMMUqDnw6CbMF4O0LQcHVgaPobMdNECNC8/aIoi8+GbMCYN0IbTKoOkinrfrXqzBNDACboVPRP8TBqgaSeeN3PacS75mSVAw0eOvKIvNoDUStwvs7oAFY/ArybAnf69zGoC+Ba91QSQ3lfSv5YsPoDP+GoCDPWzmgB7+i8QRFVvDmDxbzcqTYEd4T38VAEM4e3GHfmS4E+ag3asAJoLhCMjb+8M4YP2qwFyhl8gjJJl+GEkFMAHaNNKJrrBzhgPOcjngQrl1cmrKhgOugRoMYfxtmKBPhrQWDKMAz0aoLFk0YFesrC/mLrK6Ae9YiZAY6nAwfkWQDdt+i/6BQBpeImMehqdAAAAAElFTkSuQmCC" class="h-8 inline-block"/>
							{{ $t('vps.kvm') }}
						</h1>
						<p class="py-2">{{ $t('vps.kvm_headline') }}</p>
					</li>
					<li>
						<h1 class="text-xl font-semibold">
							<img class="inline-block h-8" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAA30lEQVRoge3YwQ2DMAyF4X+UjNQRumGzEWwQNmgP5YRIgSSOifqelBNg/AESGFAURblzAvACJuBttBLwsGo+GTa+Xc/WgNixeRNEz6tvgtgWPtp+dX/WZs0QPQBgiOgFACNETwAYIGoBV4G/EEXvCQ9ADpFGAuQQQwHOnN++QGUEEKAyAvwFIFA+sU3rscELEGgzM6QMwhzQcmKLHoCWE9vkAag9gXV9AbzrC+BdXwDv+gJ41x8fsOwU8VpzCcDj93pu7X3sHSZwj7uwkJ8ZTiEi31vo8djEmuYVRVHs8wH3kujDvO8+FQAAAABJRU5ErkJggg=="/> {{ $t('vps.snapshot') }}
						</h1>
						<p class="py-2">{{ $t('vps.snapshot_desc') }}</p>
					</li>
					<li>
						<h1 class="text-xl font-semibold"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACOUlEQVRoge2YO0gcURSGP8HCVUS3SSFapfGR0kC6NBamjI1gIzYpbHysASGS1kaSJlVA0iRVCDaGkCqVFqk0IYWIdr7wgQ8klazF7Mpw9u7M3DOPO8X8MM3OnHO+bx7LnYEiRYqkkRJQAU5rW6X2W+5TAuaBY6AqtuPavlyKBIHnWsQGPFci9Xv8JADwClgK2F/fTsjwGYlyxi+At0BnrSYXVyQq+JIPvB6nt1YU8HPgjQFcK5CYyHAI+CWwCHSE9NEK+EWGNQJ7TRqeAQsRwJMSqAK7tvAlQ5NTYA77S5qEQNVWoEsUXyvAnQq0i+ILJbwzAdNglwKqE3gumrQ7FNjRDP4nmvQ7FPilGfxdNHnhUGBVM3hFNJlzKFDRDJ4UTb46FBjRDB4STQ4dCpSVszlLYLhtnov6LS08wJoDgQ+i/n0cgYmMBVppvOrP4gi0ATcZCoyL2v048PXIS5qmwKaonYmPD4+V4LYCI6LuluZvedZZV4DbCvwWdSsJsQPwBLiLAKsVWBQ1V8T472+WTxFgNQJPDTWqpUNYHtG4xI4r0Iv3quo/fhvv7zSVvAyBtREoA38Nxw+mxP6QVcNQW4Ey3hJBHjudJrg/PwzDowr0YT7zy6lT+1ICNgwQYQJjeB/E5DEfM6EW6QB+GmBMAj3A5yb732VKbcgXggWWA/a9dsDbkBZgFvhP9OfiCBh1ARuUAeAP4fDfgG5HjKFpBabwlsESfJscnvWgvMJ7jz7A+0BQpEiRFHIP/EPFgILRNKoAAAAASUVORK5CYII=" class="inline-block h-8"/>{{ $t('vps.arbor') }}</h1>
						<p class="py-2">{{ $t('vps.arbor_desc') }}</p>
					</li>
				</ul>
			</div>
			<div class="shadow hidden sm:block" :style="{'background-image': `url(${require('../assets/img/server.jpg')})`, 'background-size': `cover`, 'background-position': 'center'}"></div>
		</div>

		<div class="bg-gray-50">
			<Footer />
		</div>
	</div>
</template>

<script>
	import Navbar from "@/components/Navbar";
	import Footer from "@/components/Footer";
	export default {
		name: 'VPS',
      metaInfo: () => ({
        title: 'VPS HydroSaaS',
        titleTemplate: '%s | Infrastructure Cloud adaptée à vos besoins',
        meta: [
          { name: 'description', content: 'Grâce à l\'infrastructure cloud de HydroSaaS, menez votre projet en toute confiance. Bénéficiez de serveurs privés (VPS) adaptés, performants et fiables.' },
          { name: 'og:title', content: 'VPS HydroSaaS : Infrastructure cloud adaptée à vos besoins' },
          { name: 'twitter:title', content: 'VPS HydroSaaS : Le Cloud adapté à vos besoins' },
          { name: 'og:description', content: 'Grâce à l\'infrastructure cloud de HydroSaaS, menez votre projet en toute confiance. Bénéficiez de serveurs privés (VPS) adaptés, performants et fiables.' },
          { name: 'twitter:description', content: 'Grâce à l\'infrastructure cloud de HydroSaaS, menez votre projet en toute confiance. Bénéficiez de serveurs privés (VPS) adaptés, performants et fiables.' },
          { name: 'twitter:image', content: 'https://hydrosaas.com/vps.png' },
          { name: 'og:image', content: 'https://hydrosaas.com/vps.png' },
        ],
      }),
      htmlAttrs: {
        amp: true
      },
		components: {
			Footer,
			Navbar,
		},
	}
</script>
